<template>
  <div id="app">
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-main>
        <router-view :key="$route.fullPath"/>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
body {
  margin: 0;
  color: #3b426b;
  font-size: 16px;
}

.mar_t_40 {
  margin-top: 40px;
}

.mar_b_40 {
  margin-bottom: 40px;
}

.el-header {
  width: 100%;
  height: 80px !important;
  padding: 0 !important;
  position: fixed;
  top: 0;
  z-index: 999;
}

.el-main {
  margin-top: 80px;
  padding: 0 !important;
  overflow: hidden !important;
}

.el-footer {
  padding: 0 !important;
}

.content {
  margin: 0 auto;
  width: 1200px;
}

.gray_bg {
  background-color: #f5f8ff;
}

.column {
  padding: 40px 0;
}

.column p {
  margin: 0;
  font-size: 28px;
}

.column p i {
  margin-left: 10px;
  color: #e1e6ff;
  font-family: Impact;
  font-size: 26px;
  font-style: italic;
}

.column span {
  margin-top: 20px;
  display: block;
  color: #858ab4;
}

.sub_banner {
  margin-bottom: 80px;
  height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
}

.sub_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  position: absolute;
  top: 20%;
  width: 1000px;
  text-align: center;
}

.title h3 {
  margin: 0 0 30px 0;
  color: #fff;
  font-size: 36px;
}

.title p {
  margin: 0;
  color: #fff;
  font-size: 18px;
  line-height: 2;
}

.title_box {
  position: absolute;
  top: 30%;
  width: 1200px;
}

.title2 {
  width: 800px;
}

.title2 h3 {
  margin: 0 0 30px 0;
  color: #fff;
  font-size: 36px;
}

.title2 p {
  margin: 0;
  color: #fff;
  font-size: 18px;
  line-height: 2;
}

.type_tab .el-tabs__header {
  margin: 0;
  top: 500px;
}

.type_tab .el-tabs__content {
  top: -80px;
}

.type_tab .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

.type_tab .el-tabs__nav-wrap::after {
  background-color: #fff;
}

.type_tab .el-tabs__item {
  height: 80px;
  line-height: 80px;
}

.type_tab .el-tabs__item:hover {
  color: #4977fc;
}

.type_tab .el-tabs__item.is-active {
  color: #4977fc;
}

.type_tab .el-tabs__active-bar {
  background-color: #4977fc;
}

.detail {
  color: #858ab4;
  font-size: 15px;
  line-height: 2;
  text-indent: 2em;
}

.detail p {
  margin: 15px 0;
}

.highlights {
  text-align: center;
}

.highlights img {
  width: 64px;
  height: 64px;
  padding: 16px;
  border: 2px dotted #a4bbfd;
  border-radius: 50%;
  background-color: #f5f8ff;
}

.highlights p {
  font-size: 18px;
}
</style>
