<template>
  <el-row>
    <el-carousel height="500px" :interval="5000">
      <el-carousel-item class="banner">
        <img src="../assets/images/banner1.jpg" alt=""/>
      </el-carousel-item>
      <el-carousel-item class="banner">
        <img src="../assets/images/banner5.jpg" alt=""/>
      </el-carousel-item>
      <el-carousel-item class="banner">
        <img src="../assets/images/banner2.jpg" alt=""/>
      </el-carousel-item>
      <el-carousel-item class="banner">
        <img src="../assets/images/banner3.jpg" alt=""/>
      </el-carousel-item>
      <el-carousel-item class="banner">
        <img src="../assets/images/banner4.jpg" alt=""/>
      </el-carousel-item>
    </el-carousel>
    <div class="content">
      <p class="slogan">围绕视频分析技术为核心，以数据应用为导向的系统提供商</p>
      <el-row class="item">
        <el-col :span="6">
          <img src="../assets/images/item_icon1.png" alt=""/>
          <p>人工智能</p>
          <span>对数据深度分析，持续学习</span>
        </el-col>
        <el-col :span="6">
          <img src="../assets/images/item_icon2.png" alt=""/>
          <p>边缘计算</p>
          <span>轻量级、可搭载多种主流算法</span>
        </el-col>
        <el-col :span="6">
          <img src="../assets/images/item_icon3.png" alt=""/>
          <p>物联网</p>
          <span>万物互联监测、分析、控制</span>
        </el-col>
        <el-col :span="6">
          <img src="../assets/images/item_icon4.png" alt=""/>
          <p>视频融合</p>
          <span>支持多种视频协议、多路数</span>
        </el-col>
      </el-row>
    </div>
    <el-row class="gray_bg">
      <div class="content">
        <el-row class="column">
          <p>解决方案<i>SOLUTION</i></p>
          <span>我们为客户提供全方位的服务与支持，致力于数据应用为基础的业务实践和解决方案。</span>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="plan">
              <img src="../assets/images/plan_img0.jpg" alt=""/>
              <div class="mask">
                <img src="../assets/images/plan_icon0.png" alt=""/>
                <h3>路桥项目管理云平台</h3>
                <p>
                  路桥项目管理云平台主要为项目业主及各层参与单位投资、建设、运营、养护多个工程项目的复杂管理过程而定制，旨在建立一个现代化、务实、高效的信息化管理平台，确保高质量、低成本的完成项目业主及各层参与单位的管理工作。</p>
                <router-link to="/solution/0">
                  <el-button type="primary">查看更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                </router-link>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="plan">
              <img src="../assets/images/plan_img1.jpg" alt=""/>
              <div class="mask">
                <img src="../assets/images/plan_icon1.png" alt=""/>
                <h3>智慧安全校园</h3>
                <p>
                  系统平台将围绕“人像可视化、服务管理精细化、数据管理可视化、人员管控高效化”的智慧安全校区设计构建理念，有效集成视频、闸机、报警、人脸布控卡口等系统于一体，形成以校园安保力量为主，人脸布控系统全面监控为辅的可视化管理平台，实现校园管理立体化、可视化和可控化，构建管理、防范、控
                  制于一体的校园治安防控体系。</p>
                <router-link to="/solution/1">
                  <el-button type="primary">查看更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                </router-link>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="plan">
              <img src="../assets/images/plan_img2.jpg" alt=""/>
              <div class="mask">
                <img src="../assets/images/plan_icon2.png" alt=""/>
                <h3>智慧司法</h3>
                <p>
                  突出信息技术引领，以智慧建设入手，用
                  “AI+互联网+公共法律服务”思维实现“民呼我应、企呼我应”，畅通公共法律服务最后一公里，打造全面覆盖司法行政机关、社会群众、企业、律师四个方向，构筑“线上、线下+公共法律服务平台”全域体系化、资源化、高价值的司法行政服务体系，建设“大整合、高共享、全规范”的智慧司法管理平台。</p>
                <router-link to="/solution/5">
                  <el-button type="primary">查看更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                </router-link>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="plan">
              <img src="../assets/images/plan_img3.jpg" alt=""/>
              <div class="mask">
                <img src="../assets/images/plan_icon3.png" alt=""/>
                <h3>智慧明亮厨房</h3>
                <p>针对后厨人员操作规范、食品卫生安全、明火检测、后厨人员管理等智能化管理，做到后厨管理智能化。使内外部人员实现可见、可闻、可监督，为餐饮消费市场营造安全、卫生、可信的放心消费环境。</p>
                <router-link to="/solution/2">
                  <el-button type="primary">查看更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                </router-link>
              </div>
            </div>
          </el-col>
          <!--          <el-col :span="6">-->
          <!--            <div class="plan">-->
          <!--              <img src="../assets/images/plan_img4.jpg" alt=""/>-->
          <!--              <div class="mask">-->
          <!--                <img src="../assets/images/plan_icon4.png" alt=""/>-->
          <!--                <h3>智慧宿管</h3>-->
          <!--                <p>-->
          <!--                  智慧宿管平台，一站式、精细化管理，人脸识别抓取、智能门锁闸机、云考勤、智能水电等助力智慧宿管。支持建立多级宿舍管理组织结构，实现多校区、多宿舍楼栋管理，高效率实现宿舍管理工作，并通过大数据、图表实时展示宿舍整体运行状态。</p>-->
          <!--                <router-link to="/solution/3">-->
          <!--                  <el-button type="primary">查看更多<i class="el-icon-arrow-right el-icon&#45;&#45;right"></i></el-button>-->
          <!--                </router-link>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </el-col>-->
        </el-row>
      </div>
    </el-row>
    <div class="content">
      <el-row class="column">
        <p>产品中心<i>PRODUCT</i></p>
        <span>加速行业迭代升级、深化数字化转型，解决AI落地“最后一公里”的难题。</span>
      </el-row>
      <el-row class="product mar_b_40">
        <el-col :span="8"><img src="../assets/images/product.png" alt=""/></el-col>
        <el-col :span="8">
          <router-link to="/product/2"><p>可视化融合平台</p></router-link>
          <span>对各种来源视频类资源进行整合，结合人工智能和大数据分析，提供面向业务的视频、人像、车辆等智能分析与数据服务；通过定制开发或集成，为公安、检察、法院、教育、医疗、企业生产等用户提供深入场景的行业应用。</span>
        </el-col>
        <el-col :span="8"><img src="../assets/images/product3.png" alt=""/></el-col>
        <el-col :span="8">
          <router-link to="/product/1"><p>边缘计算盒子</p></router-link>
          <span>弘新智能边缘计算设备，基于各大平台芯片，自主研发制造轻量级、可搭载多种主流算法的视频智能分析设备。为客户提供了轻量级、多平台、高算力、高能低耗的边缘计算产品，广泛应用于电力、能源、智慧社区、智慧交通、智能安防等领域行为分析。</span>
        </el-col>
        <el-col :span="8"><img src="../assets/images/product2.png" alt=""/></el-col>
        <el-col :span="8">
          <router-link to="/product/3"><p>司法智能应用终端</p></router-link>
          <span>智慧法律服务平台涵盖司法局、社会群众、企业、律师、社区等，构筑“线上、线下+公共法律服务平台”全域体系化、资源化、高价值的司法行政服务体系，建设“大整合、高共享、全规范”的“一站式”法律综合服务平台。</span>
        </el-col>
      </el-row>
    </div>
    <el-row class="gray_bg">
      <div class="content">
        <el-row class="column">
          <p>服务优势<i>ADVANTAGE</i></p>
          <span>利用创新技术，为客户带来更智能化、更高效的应用体验，同时洞察价值数据，将数据价值最大化的服务商。</span>
        </el-row>
        <el-row class="point">
          <el-col :span="6">
            <img src="../assets/images/item_icon5.png" alt=""/>
            <p>专业的开发团队</p>
            <span>有丰富的算法到场景化应用产品的业务建设、开发经验。</span>
          </el-col>
          <el-col :span="6">
            <img src="../assets/images/item_icon6.png" alt=""/>
            <p>可靠的产品</p>
            <span>不断提升视频处理技术和智能分析技术，具有实用性强、便于推广等特点。</span>
          </el-col>
          <el-col :span="6">
            <img src="../assets/images/item_icon7.png" alt=""/>
            <p>精准适配您的业务</p>
            <span>从数据到场景，解析您的业务DNA，精准构建专属的业务体验。</span>
          </el-col>
          <el-col :span="6">
            <img src="../assets/images/item_icon8.png" alt=""/>
            <p>成熟的供应链体系</p>
            <span>稳定的供应链管理，确保供货稳定性，提高了产品性价比。</span>
          </el-col>
        </el-row>
      </div>
    </el-row>
  </el-row>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>

<style>
.banner {
  height: 500px;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slogan {
  font-size: 28px;
  text-align: center;
}

.item {
  padding: 20px 0 40px 0;
}

.item .el-col-6 {
  padding: 0 20px;
  border-right: 1px solid #e1e6ff;
  text-align: center;
  line-height: 1.5;
}

.item .el-col-6:last-child {
  border-right: none;
}

.item .el-col-6 img {
  width: 140px;
  height: 88px;
}

.item .el-col-6 p {
  margin: 10px 0 15px 0;
  font-size: 24px;
}

.item .el-col-6 span {
  color: #858ab4;
}

.plan {
  margin-bottom: 40px;
  height: 360px;
  position: relative;
}

.plan img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .4);
  color: #fff;
  text-align: center;
}

.mask img {
  margin-top: 120px;
  width: 64px;
  height: 64px;
}

.mask h3 {
  margin: 20px 0;
  font-size: 24px;
  font-weight: normal;
}

.mask p {
  margin: 0;
  font-size: 14px;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  opacity: 0;
  transition: all 0.3s;
}

.mask .el-button--primary {
  margin-top: 20px;
  background-color: #4977fc;
  border-color: #4977fc;
  opacity: 0;
  transition: all 0.3s;
}

.plan:hover .mask {
  background-color: rgba(0, 0, 0, .6);
  transition: all 0.6s;
}

.plan:hover .mask img {
  margin-top: 20px;
  width: 50px;
  height: 50px;
  transition: all 0.6s;
}

.plan:hover .mask h3 {
  font-size: 22px;
  transition: all 0.6s;
}

.plan:hover .mask p {
  opacity: 1;
  transition: all 0.6s;
}

.plan:hover .mask .el-button--primary {
  opacity: 1;
  transition: all 0.6s;
}

.product .el-col-8 {
  height: 220px;
}

.product .el-col-8:nth-child(even) {
  background-color: #f5f8ff;
  padding: 0 20px;
}

.product .el-col-8 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.6s;
}

.product .el-col-8 img:hover {
  transform: scale(1.2);
}

.product .el-col-8 a {
  color: #3b426b;
  text-decoration: none;
}

.product .el-col-8 p {
  margin: 0 0 20px 0;
  padding: 20px 0;
  font-size: 22px;
  position: relative;
}

.product .el-col-8 p:after {
  content: "";
  width: 100px;
  height: 1px;
  background-color: #4977fc;
  position: absolute;
  left: 0;
  bottom: 0;
}

.product .el-col-8 span {
  color: #858ab4;
  font-size: 14px;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.point {
  padding: 20px 0 40px 0;
}

.point .el-col-6 {
  padding-right: 40px;
}

.point .el-col-6 img {
  width: 100px;
  height: 111px;
}

.point .el-col-6 p {
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.point .el-col-6 span {
  color: #858ab4;
  font-size: 14px;
  line-height: 2;
}
</style>
