<template>
  <div class="bottom">
    <div class="content">
      <el-row class="foot_nav">
        <el-col :span="9">
          <div class="heading">
            <p>弘新智能科技(武汉)有限公司</p>
            <p>HONGXIN INTELLIGENT TECHNOLOGY (WUHAN) CO., LTD.</p>
          </div>
          <div class="sub_info">
            <p>商务合作：13100645321(谭经理)</p>
            <p>联系电话：027-59726266 027-59722099</p>
            <p>公司地址：武汉市光谷大道35号光谷总部时代二期5栋10楼</p>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="heading">
            <p>解决方案</p>
            <p>SOLUTION</p>
          </div>
          <div class="sub_info">
            <router-link to="/solution/1"><p>智慧安全校园</p></router-link>
            <router-link to="/solution/5"><p>智慧司法</p></router-link>
            <router-link to="/solution/3"><p>智慧宿管</p></router-link>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="heading">
            <p>产品与服务</p>
            <p>PRODUCTS & SERVICES</p>
          </div>
          <div class="sub_info">
            <router-link to="/product/1"><p>弘新智能边缘计算盒子</p></router-link>
            <router-link to="/product/2"><p>可视化融合平台</p></router-link>
            <router-link to="/product/3"><p>司法智能应用终端</p></router-link>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="heading">
            <p>关于我们</p>
            <p>ABOUT US</p>
          </div>
          <div class="sub_info">
            <router-link to="/about"><p>关于弘新</p></router-link>
            <router-link to="/about"><p>荣誉资质</p></router-link>
            <router-link to="/about"><p>联系我们</p></router-link>
          </div>
        </el-col>
      </el-row>
      <el-row class="foot_copyright">
        <p>弘新智能科技(武汉)有限公司 版权所有</p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2022000390号 工业和信息化部备案管理系统网站</a></p>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.bottom {
  background-color: #2d333d;
}

.foot_nav {
  padding: 40px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.heading {
  margin-bottom: 15px;
  color: #fff;
  font-size: 14px;
}

.sub_info {
  color: rgba(255, 255, 255, .6);
  font-size: 12px;
}

.heading p {
  margin: 0;
  line-height: 2;
}

.sub_info a {
  color: rgba(255, 255, 255, .6);
  text-decoration: none;
}

.sub_info p {
  margin: 0;
  line-height: 2.5;
}

.foot_copyright {
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, .2);
  color: rgba(255, 255, 255, .6);
  font-size: 14px;
  text-align: center;
}

.foot_copyright p {
  margin: 0;
  line-height: 2;
}

.foot_copyright p a {
  color: rgba(255, 255, 255, .6);
  text-decoration: none;
}
</style>
