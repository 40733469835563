<template>
  <el-row class="top">
    <div class="content">
      <el-row type="flex" justify="space-between" align="middle" class="nav">
        <img class="logo" src="../assets/images/logo.png" alt="弘新智能"/>
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" :router="true">
          <el-menu-item index="/home">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">解决方案</template>
            <el-menu-item index="/solution/0">路桥项目管理云平台</el-menu-item>
            <el-menu-item index="/solution/1">智慧安全校园</el-menu-item>
            <el-menu-item index="/solution/2">智慧明亮厨房</el-menu-item>
            <el-menu-item index="/solution/3">智慧宿管</el-menu-item>
            <el-menu-item index="/solution/4">智慧菜场</el-menu-item>
            <el-menu-item index="/solution/5">智慧司法</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">产品与服务</template>
            <el-menu-item index="/product/1">弘新智能盒子</el-menu-item>
            <el-menu-item index="/product/2">可视化融合平台</el-menu-item>
            <el-menu-item index="/product/3">司法智能应用终端</el-menu-item>
            <el-menu-item index="/product/4">产品视频介绍</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">产品演示</template>
            <el-menu-item>
              <a target="_blank"
                 href="http://product.datall.cn/service/preview/?id=7f925bc2-a208-4ec7-b5ba-b72dfd780770">智慧农业GIS</a>
            </el-menu-item>
            <el-menu-item>
              <a target="_blank"
                 href="http://iot.wise777.com/#/user/login">智慧物联网平台</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/about">关于我们</el-menu-item>
        </el-menu>
      </el-row>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
      activeIndex: '/home',
    };
  },
  created() {
    const activeIndex = window.localStorage.getItem('activeIndex');
    activeIndex && (this.activeIndex = activeIndex)
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
      window.localStorage.setItem('activeIndex', to.path);
    }
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      window.localStorage.setItem('activeIndex', key);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  height: 80px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(133, 138, 180, .2);
}

.nav {
  height: 80px;
}

.logo {
  width: 204px;
  height: 55px;
}
</style>

<style>
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu-item, .el-submenu__title {
  color: #3b426b !important;
  border-bottom: none !important;
}

.el-menu--horizontal > .el-menu-item:hover {
  color: #4977fc !important;
}

.el-submenu__title:hover {
  color: #4977fc !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #4977fc !important;
  border-bottom: none !important;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #4977fc !important;
  border-bottom: none !important;
}

.el-menu-item a {
  color: #3b426b;
  text-decoration: none;
}
</style>
